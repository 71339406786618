import * as React from "react"
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col } from 'react-bootstrap'

import Layout from "../components/layout"
import Seo from "../components/seo"

const SecondPage = () => (
  <Layout>
    <Container className="singleCol">
      <h1 className="text-center my-4">Tickets</h1> 
      <p className="text-center">
        <a href="https://www.simpletix.com/e/ralph-mark-gilbert-civil-rights-museum-of-tickets-123039" className="btn btn-primary"><strong>Purchase Tickets Online</strong></a>
      </p>   
       
      <div className="d-md-flex justify-content-evenly mt-4">
        <div>
          <h2>Individual Tickets</h2>
          <ul className="list-unstyled lead">
            <li>Adult $14</li>
            <li>Seniors $8</li>
            <li>Students $7</li>
          </ul>
        </div>
        <div>
          <h2>Group Tickets (10 or more)</h2>
          <ul className="list-unstyled lead">
            <li>Adult $10</li>
            <li>Seniors $7</li>
            <li>Students $6</li>
            <li>Educators with students $6</li>
          </ul>
          {/* <p><small>A minimum of 10 tickets or more is required</small></p> */}
        </div>
      </div>

      <hr />
      <div className=" py-4">
        <h2 className="text-center">Tuesday & Wednesday Group Tickets</h2>
        <h3 className="text-center mb-3">For groups of 10 or more</h3>
        <p className="text-center">
          <a href="https://www.simpletix.com/e/ralph-mark-gilbert-civil-rights-museum-of-tickets-181204" className="btn btn-primary">Purchase Tuesday / Wednesday Group Tickets Online</a>
        </p>   
        <ul className="list-unstyled lead text-center">
            <li>Adult $10</li>
            <li>Seniors $7</li>
            <li>Educators & students $6</li>
          </ul>
        <p className="mb-0">
          Please contact  the museum at (912) 777-6099 for a specific entry time after purchasing if it hasn't been previously arranged.
        </p>
      </div>

      <hr />
      <h2 className="text-center mt-5">A  Family Reunion Summer at the Ralph Mark Gilbert Civil Rights Museum</h2>
      <p>
      July and August were busy months, this year, as the Museum welcomed Family Reunions. We met and led tours for members of the Queens Family, the Lowe Family, the King Matthew Brown Family and the Clayton and Della Scruggs Reunion. The highlight of the visit was to hear the eldest family member share the Family history and the youngest looking toward the future while learning about the past. 
      </p>
      <p>Think about bringing your Family Reunion next year! </p>
      <Row className="g-5 mb-4">
        <Col xs={6}>
          <StaticImage
            src="../images/family-renunion-vert-01.jpg"
            quality={95}
            width={450}
            formats={["auto", "webp", "avif"]}
            alt="Family Renunion"
          />
        </Col>
        <Col xs={6}>
          <StaticImage
            src="../images/family-renunion-vert-02.jpg"
            quality={95}
            width={450}
            formats={["auto", "webp", "avif"]}
            alt="Family Renunion"
          />
        </Col>
      </Row>
      <p>
        <StaticImage
          src="../images/family-renunion-horizontal.jpg"
          quality={95}
          formats={["auto", "webp", "avif"]}
          alt="Family Renunion"
        />
      </p>
    </Container>      
  </Layout>
)

export const Head = () => <Seo title="Purchase Tickets to the Museum" />

export default SecondPage
